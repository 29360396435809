<template>
  <v-sheet
      v-if="display"
      class="webinarDialog"
      v-model="display"
      :title="$t('Title')"
      :color="wsDARKER"
  >
    <div class="fill-height d-flex flex-column" style="position: relative">

      <!-- Header  -->
      <ws-webinar-room-header
          @close="display = false"
          :name="webinar.name"
      />

      <!-- Content-->
      <div style="height: 100%; min-height: 35px; background-color: var(--wsDARKER)"
           class="py-6">
        <div class="fill-height d-flex flex-row"  >

          <!-- Video Content -->
          <div class="flex-grow-1">
            <webinar-video-view user />
          </div>

          <!-- Right Sidebar -->
          <div class="px-6" >
            <ws-right-sidebar user />
          </div>

        </div>

      </div>

    </div>


    <!-- DIALOGS-->

    <!--   Webinar preview dialog   -->
<!--    <ws-webinar-preview-dialog-->
<!--      v-if="displayPreviewDialog"-->
<!--      v-model="displayPreviewDialog"-->
<!--      @exit-webinar="display = false"-->
<!--    />-->
  </v-sheet>

</template>

<script>

import WsWebinarRoomHeader from "@modules/webinars/components/student/webinar-room/UI/wsWebinarRoomHeader.vue";
import WebinarVideoView from "@modules/webinars/components/Webinar/admin/room/stream/webinarVideoView.vue";
import {mapActions} from "vuex";
import WsRightSidebar from "@modules/webinars/components/Webinar/admin/room/sidebars/wsRightSidebar.vue";

// import WsMediaController from "@modules/webinars/plugins/wsMediaController";
import webinars from "@modules/webinars/mixins/webinars";


export default {
  name: "StudentWebinarRoom.vue",
  mixins: [webinars],
  components: {
    // WsWebinarPreviewDialog,
    WebinarVideoView,
    WsRightSidebar,
    WsWebinarRoomHeader
  },
  props: {
    value: {
      type: Boolean,
    },
    webinar: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      display : false,
      isActiveSidebarWindow: false,
      checkedValue: '',
      // displayPreviewDialog: true,

    }
  },
  computed: {
    loading() {
      return !this.WEBINAR.uuid
    }
  },
  methods: {
    ...mapActions('webinars', [
        'GET_ONE_WEBINAR',
        'GET_WEBINAR_CHAT_TOKEN'
    ]),

    async connectToChat() {
      let result = await this.GET_WEBINAR_CHAT_TOKEN()
      if (!result) {
        return this.ERROR('Error connecting to Chat')
      }
      this.$chats.setUser(result.user)
      this.$store.state.fineChats.user = result.user
      await this.$chats.socket.connect(result.auth)

      if (result && this.webinar.chat_uid) {
        let chatDetails = await this.$chats.socket.selectChat(this.webinar.chat_uid)
        if (chatDetails) {
          this.$store.state.fineChats.selectedChatUid = this.webinar.chat_uid
        }

      }

    },

    async getWebinarDetails() {
      this.$store.state.webinars.webinar = this.COPY(this.webinar)
      this.connectToChat()

    },

    async initMediaController() {
      // this.$store.state.stream.wsMediaController = new WsMediaController()
      // this.$store.state.stream.isMediaReady = await this.MEDIA_CONTROLLER.init()
      // console.log(this.MEDIA_CONTROLLER)
    },


  },
  async mounted() {

    await this.initMediaController()

    if ( this.value ) {
      this.display = this.value
    }

    await this.getWebinarDetails()
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('stream/FLUSH_VARIABLES')
  }
}
</script>

<style scoped>
.webinarDialog {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

</style>
